
import { gsap } from "gsap";


export const homeSliderInit = () => {

    const sliderPanelWrapper = document.querySelector('.section_slider-home');
    const sliderPanels = document.querySelectorAll('.home-slider-panel');
    const sliderPanelsBg = document.querySelectorAll('.home-slider-bg');

    if(sliderPanels.length <= 0){
        return;
    }

    const sliderPanelsVirtualLength = sliderPanels.length + 1;
    
    const lastSlider = sliderPanels[sliderPanels.length-1];
    const lastSliderAlias = lastSlider.cloneNode(true);
    lastSliderAlias.classList.remove('is-3');
    lastSliderAlias.classList.add('is-0');
    sliderPanelWrapper.insertBefore(lastSliderAlias, sliderPanels[0]);

    const lastSliderBg = sliderPanelsBg[sliderPanelsBg.length-1];
    const lastSliderBgAlias = lastSliderBg.cloneNode(true);
    lastSliderBgAlias.classList.remove('is-3');
    lastSliderBgAlias.classList.add('is-0');
    sliderPanelWrapper.insertBefore(lastSliderBgAlias, sliderPanels[0]);


    var sliderTl = gsap.timeline({paused: true});
    let panelCount = 1;
    const xRightVal = window.innerWidth <= 768 ? 100 : 53;
    const degree = '20';
    const scaleVal = .8;

    gsap.set('.home-slider-panel.is-0', {x: `0%`, zIndex: 2});
    gsap.set('.home-slider-panel.is-1', {pointerEvents: "none", x: `${xRightVal}vw`, scale: scaleVal, zIndex: 3, rotate: `-${degree}deg`});
    gsap.set('.home-slider-panel.is-2', {pointerEvents: "none", x: `${xRightVal}vw`, scale: scaleVal, zIndex: 3, rotate: `-${degree}deg`});
    gsap.set('.home-slider-panel.is-3', {pointerEvents: "none", x: '100%', scale: scaleVal, zIndex: 3, rotate: `-${degree}deg`});


    for(let i = 0; i <= sliderPanelsVirtualLength; i++){

        let currentSlide = i;
        let nextSlide = currentSlide+1;
        let incomingSlide = nextSlide+1;

        sliderTl.addLabel(`panel-${currentSlide}`);

        sliderTl
            .to(`.home-slider-panel.is-${currentSlide} .slider_icon`, {ease: "power4.in", duration: 0.6, scale: 0, stagger: 0.1 })
            .fromTo(`.home-slider-panel.is-${currentSlide}`, 
                {x: '0%', autoAlpha: 1, rotate: `0deg`, scale: '1', zIndex: 3 }, 
                {ease: "power4.inOut", duration: 1, x: '-100%', rotate: `${degree}deg`, scale: scaleVal }, '-=0.4')
            .set(`.home_slider-cta.is-${currentSlide}`, { display: "none" })
            .set(`.home-slider-panel.is-${currentSlide}`, { pointerEvents: "none" });
            

            if(nextSlide <= sliderPanelsVirtualLength){

                sliderTl
                .set(`.home-slider-panel.is-${nextSlide}`, { pointerEvents: "all" })
                .set(`.home_slider-cta.is-${nextSlide}`, { display: "block" })
                .fromTo(`.home-slider-panel.is-${nextSlide}`, 
                    {x: '100%', autoAlpha: 0, rotate: `-${degree}deg`, scale: scaleVal, zIndex: 1 }, 
                    {ease: "power4.inOut", duration: 1.4, autoAlpha: 1, x: '0%', rotate: `0deg`, scale: 1, zIndex: 3 }, '-=1.2')
                .from(`.home-slider-bg.is-${nextSlide}`, {ease: "power4.in", duration: 2, x: '125%' }, '-=2.4')
                .from(`.home-slider-panel.is-${nextSlide} .slider_heading`, {ease: "power4.inOut", duration: 1, autoAlpha: 0, scale: .1 }, '-=1.1')
                .from(`.home-slider-panel.is-${nextSlide} .slider_title`, {ease: "power4.inOut", duration: 1, autoAlpha: 0, scale: .1 }, '-=1.1')
                .from(`.home-slider-panel.is-${nextSlide} .slider_icon`, {ease: "back.out(1.7)", duration: 0.8, scale: 0, autoAlpha: 0, stagger: 0.1 }, '-=1.1')
                .from(`.home-slider-panel.is-${nextSlide} .bubble`, { duration: 0.8, autoAlpha: 0, stagger: 0.1 }, '-=0.7');
            }

            if(incomingSlide <= sliderPanelsVirtualLength){
                sliderTl.to(`.home-slider-panel.is-${incomingSlide}`, {ease: "power4.inOut", duration: 1, x: `${xRightVal}vw` }, '-=1.8');
            }
    }

    sliderTl.seek('panel-1');

    const nextPanelTrigger = document.querySelector('.home_slider-arrow-right');
    const prevPanelTrigger = document.querySelector('.home_slider-arrow_left');

    nextPanelTrigger.addEventListener('click', function(){

        if(panelCount == sliderPanels.length){
            sliderTl.seek('panel-0');
            panelCount = 0;
        }

        panelCount++;
        sliderTl.tweenTo(`panel-${panelCount}`);
    });

    prevPanelTrigger.addEventListener('click', function(){

        if(panelCount == 0){
            sliderTl.seek('panel-3');
            panelCount = 3;
        }

        panelCount--;
        sliderTl.tweenTo(`panel-${panelCount}`);
    });

}