import './style.css';

import { loaderInit } from './js/loader.js';

import anime from 'animejs/lib/anime.es.js';

import { homeSliderInit } from './js/sliderHomeAni.js';
import { timeTickerAni } from './js/timeTickerAni.js';
import { homeWordsAni } from './js/homeWordsAni.js';
import { sliderServiceAni } from './js/sliderAni.js';
import './js/menuAni.js';

loaderInit();
homeSliderInit();
timeTickerAni();
homeWordsAni();
sliderServiceAni();


let spreadLettersAni = document.querySelectorAll('[data-animation="spread-letters"]');

spreadLettersAni.forEach((item) => {

    item.innerHTML = item.textContent.replace(/\S/g, "<span class='letter' style='display: inline-block'>$&</span>");

    let letters = item.querySelectorAll('.letter');
    
        item.addEventListener('mouseenter', function(){
    
        anime({
        targets: letters,
        rotate: function() { return anime.random(-10, 10); },
        translateY: function() {
            return anime.random(0, -20);
        },
        delay: function() { return anime.random(0, 100); },
        direction: 'alternate',
        easing: 'spring(1, 100, 10, 10)',
        loop: false
        });
        
    });

    item.addEventListener('mouseleave', function(){
    
        anime({
        targets: letters,
        rotate: 0,
        translateY: 0,
        delay: function() { return anime.random(0, 100); },
        direction: 'alternate',
        easing: 'spring(1, 80, 10, 10)',
        loop: false
        });
        
    });
});


// Button border animation
let buttonBorderAni = document.querySelectorAll('[data-animation-border]');

buttonBorderAni.forEach((item) => {
  
  let path = item.querySelectorAll('.button-border');
  
	item.addEventListener('mouseenter', function(){
  
    anime({
        targets: path,
        strokeDashoffset: [anime.setDashoffset, 0],
        duration: 400,
        easing: 'easeInOutSine',
        loop: false
    });
    
  });
  
	item.addEventListener('mouseleave', function(){
  
    anime({
        targets: path,
        strokeDashoffset: [0, anime.setDashoffset],
        duration: 300,
        easing: 'easeInOutSine',
        loop: false
    });
  	
  });
  
});
  
  
const morphingBubble = anime({
  targets: '.service-slider_bubble path',
  d: [
    { value: 'M218.946 1.74839C342.344 1.74839 495.5 -25.0002 495.5 224.5C495.5 474 335.5 509.88 230.079 509.88C124.658 509.88 0.600952 402.953 0.600952 248.96C0.600952 109.133 78.2982 1.74839 218.946 1.74839Z' },
    { value: 'M278.946 0.748535C402.344 0.748535 497.146 105.281 497.146 227.96C497.146 366.568 418.366 508.88 278.946 508.88C124.05 508.88 0.600952 381.953 0.600952 227.96C0.600952 88.1328 138.298 0.748535 278.946 0.748535Z' },
    { value: 'M257.102 0.748535C401 0.748535 495.5 96.5 495.5 263.5C495.5 430.5 444.159 508.88 230.079 508.88C16 508.88 0.601074 342.92 0.601074 247.96C0.601074 153 48.0001 0.748535 257.102 0.748535Z' }
    
  ],
  easing: 'easeInOutSine',
  duration: 6000,
  direction: 'alternate',
  loop: true
});
  
const morphingWaveWhite = anime({
  targets: '.section-wave path',
  d: [
        { value: 'M1311 10.8686C1139 115 981 81.6313 735 15.0001C465.892 -57.8902 326.5 179.5 0 42.3325V100.499H1311V10.8686Z' }
    ],
  easing: 'easeInOutSine',
  duration: 5000,
  direction: 'alternate',
  loop: true
});
  
const morphingTeamBg = anime({
  targets: '.team-slider-bg path',
  d: [
        { value: 'M1152.62 32.7114C1130.28 13.4613 1072.95 27.8056 1042.77 28.4754C959.45 25.9246 777.401 0 605.299 0C453.579 0 310.334 22.9928 211.792 24.8376C159.072 24.7526 80.3739 7.89217 54.7932 17.0268C10.9863 43.1402 14.2173 82.9468 17.7164 126.058C20.0291 154.551 18.4589 184.486 7.50415 212.867C-8.46594 286.778 5.95264 346.349 8.03394 384.983C9.96362 420.802 10.0339 507.164 22.9626 541.014C24.1301 543.539 25.2098 545.976 26.2533 548.332C34.6582 567.305 40.709 580.964 71.293 592.028C98.8411 601.994 137.685 607.904 173.608 607.456C272.535 613.05 454.384 602 605.299 602C772.808 602 1011.62 607.155 1115.38 600.12C1129.66 600.382 1143.42 598.595 1156.54 593.842C1222.41 569.968 1194.92 476.884 1178.8 391.072C1172.35 356.738 1186.12 323.567 1185.54 296.46C1184.92 267.743 1169.33 237.803 1173.69 208.279C1183.58 141.192 1203.15 76.2553 1152.62 32.7114Z' }
    ],
  easing: 'easeInOutSine',
  duration: 3000,
  direction: 'alternate',
  loop: true
});

const morphingMemberMask = anime({
  targets: '.member-mask path',
  d: [
        { value: 'M545 0H0V545H545V0ZM22.5 258C22.5 52 278.5 -75.4999 428.5 74.4998C576.584 222.584 601 438.5 288.001 528.5C168.731 562.795 22.5 437 22.5 258Z' }
    ],
  easing: 'easeInOutSine',
  duration: 4000,
  direction: 'alternate',
  loop: true
});
  
  
const morphingTimeBg = anime({
  targets: '.home-time-bg path',
  d: [
        { value: 'M1085.4 4.89398C896 -16 691 39.0001 594 40.5001C497 42.0001 317.604 25.606 158.5 5.5C75.7599 -4.95585 15 15.001 9.39571 75.501C4.26272 130.913 -8.67832 217.145 9.39571 301.501C27.5007 386.001 29.2956 489.445 19.5008 535.001C9.39571 582 34.5 617.3 103.896 600.394C222.5 571.5 310.396 619.894 571.896 619.894C833.396 619.894 985.5 595.754 1051.9 605.394C1121.5 615.5 1188.64 618 1201.5 563C1219.5 486 1172.5 485.604 1172.5 365.5C1172.5 291.5 1205.5 252.5 1205.5 161.5C1205.5 104.968 1203.5 75.5 1183 43.5C1160.39 8.21241 1128.05 9.59989 1085.4 4.89398Z' }
    ],
  easing: 'easeInOutSine',
  duration: 5000,
  direction: 'alternate',
  loop: true
});